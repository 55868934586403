// If enabled top links in desktop header will be hidden until the user hover or interact with the header
$header-transparent-top-links-hidden--xlarge: true !default;

$header-transparent-background-transition: background 0.5s ease-out !default;
$header-transparent-color-transition: color 0.5s ease-out !default;
$header-transparent-border-transition: border-color 0.5s ease-out !default;
$header-transparent-opacity-transition: opacity 0.5s ease-out !default;
$header-transparent-dark-color: color(dark) !default;
$header-transparent-light-color: color(light) !default;
$header-transparent-opacity-background: rgba(0, 0, 0, 0.25) !default;
$header-transparent-search-input-background: rgba(0, 0, 0, 0.25) !default;

@mixin lora-helper-header-transparent-style($theme-color) {
    .c-locale-selector__select,
    .l-header__top-nav-link,
    .l-header__top-nav .c-storeslink,
    .l-header__top-nav .c-signup-link,
    .l-header__top-nav .c-user__link,
    .l-header__top-nav .c-minicart-icon__link,
    .l-header__top-nav .c-minicart-icon__timer,
    .l-header__top-nav .c-select__field,
    .c-navigation__item-title.m-level-1,
    .l-header__search-button,
    .c-select .c-select__container,
    .c-select .c-select__placeholder,
    .c-simple-search__field,
    .c-simple-search__search-label,
    .c-simple-search__field::placeholder {
        color: $theme-color;
    }

    .c-select .c-select__container::after,
    .c-select .c-select__placeholder::after,
    .c-storeslink::before,
    .c-icon.m-star .c-icon__svg::before,
    .c-icon.m-user .c-icon__svg::before,
    .c-icon.m-signup .c-icon__svg::before,
    .c-user__link.m-my-account .c-icon__svg::before,
    .c-minicart-icon__svg,
    .l-header__search-button::before,
    .c-hamburger__button::before,
    .c-simple-search__button::after,
    .c-simple-search__clear-button {
        background-color: $theme-color;
    }

    .l-header__top-nav-item > div:first-child::after,
    .l-header__top-nav-item > a:first-child::after,
    .l-header__top-nav-item > button:first-child::after {
        @include breakpoint(xlarge) {
            background: $theme-color;
        }
    }

    .l-header__search-button,
    .c-simple-search__input-group {
        @include breakpoint(xlarge) {
            border: 1px solid $theme-color;
        }
    }
}

@mixin lora-components-header-transparent {
    // Generic styles
    .header-transparent {
        // Not compatible component
        main > .c-simple-navigation {
            display: none;
        }

        // Background transitions
        .l-header,
        .l-header__main-area-wrapper,
        .l-header__hamburger,
        .c-simple-search.m-secondary .c-simple-search__input-group,
        .c-simple-search.m-secondary .c-simple-search__field,
        .c-simple-search.m-inline .c-simple-search__input-group,
        .c-simple-search.m-inline .c-simple-search__field {
            transition: $header-transparent-background-transition;
        }

        // Color transitions
        .c-locale-selector__select,
        .l-header__top-nav-link,
        .l-header__top-nav .c-storeslink,
        .l-header__top-nav .c-signup-link,
        .l-header__top-nav .c-user__link,
        .l-header__top-nav .c-minicart-icon__link,
        .l-header__top-nav .c-minicart-icon__timer,
        .l-header__top-nav .c-select__field {
            transition: $header-transparent-color-transition;
        }

        .l-header__search-button {
            transition: $header-transparent-background-transition, $header-transparent-color-transition;
        }

        .c-select .c-select__container,
        .c-select .c-select__placeholder,
        .c-user.m-dark {
            transition: $header-transparent-color-transition, $header-transparent-background-transition, $header-transparent-border-transition, $header-transparent-opacity-transition;
        }

        // Icons
        .c-select .c-select__container::after,
        .c-select .c-select__placeholder::after,
        .c-storeslink::before,
        .c-icon.m-star .c-icon__svg::before,
        .c-minicart-icon__svg,
        .c-user__link .c-icon__svg::before,
        .l-header__search-button::before,
        .c-simple-search__button::after,
        .c-simple-search__clear-button,
        .l-header__top-nav-item > div:first-child::after,
        .l-header__top-nav-item > a:first-child::after,
        .l-header__top-nav-item > button:first-child::after,
        .c-hamburger__button::before {
            transition: $header-transparent-background-transition;
        }

        .l-header:not(:hover):not(.m-sticked):not(.m-focused),
        .c-simple-search.m-secondary .c-simple-search__field,
        .c-simple-search.m-inline .c-simple-search__field,
        .c-simple-search.m-secondary,
        .c-hamburger__button {
            background: transparent;
        }

        /* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
        .l-header:not(.m-sticked):not(.m-focused):not(.m-search-active):not(.m-search-suggestions-active) {
            .l-header__main-area-wrapper {
                position: absolute;
                width: 100%;
                left: 0;
                top: 100%;
            }
        }

        .l-header:not(:hover):not(.m-sticked):not(.m-focused):not(.m-search-active):not(.m-search-suggestions-active) {
            .l-header__main-area-wrapper {
                background: transparent;

                @include breakpoint(large down) {
                    &::before {
                        display: none;
                    }
                }
                
                .l-header__hamburger,
                .l-header__hamburger::before,
                .l-header__hamburger::after,
                .l-header__search-button,
                .c-hamburger__button,
                .c-simple-search,
                .c-select .c-select__container,
                .c-select .c-select__placeholder,
                .c-user.m-dark {
                    background: transparent;
                    border-color: transparent; //stylelint-disable scale-unlimited/declaration-strict-value
                }

                .c-simple-search.m-secondary .c-simple-search__input-group,
                .c-simple-search.m-inline .c-simple-search__input-group,
                .c-simple-search__field:focus + .c-simple-search__search-label::before,
                .c-simple-search__field:not(:placeholder-shown) + .c-simple-search__search-label::before {
                    background: $header-transparent-search-input-background;
                }
            }

            @if ($header-transparent-top-links-hidden--xlarge) {
                @include breakpoint(xlarge) {
                    .c-locale-selector__select,
                    .l-header__top-nav-link,
                    .l-header__top-nav .c-storeslink,
                    .l-header__top-nav .c-signup-link,
                    .l-header__top-nav .c-user__link,
                    .l-header__top-nav .c-minicart-icon__link,
                    .l-header__top-nav .c-minicart-icon__timer,
                    .l-header__top-nav .c-select__field,
                    .l-header__top-nav-item > div:first-child::after,
                    .l-header__top-nav-item > a:first-child::after,
                    .l-header__top-nav-item > button:first-child::after,
                    .c-select .c-select__container,
                    .c-select .c-select__placeholder,
                    .c-user.m-dark {
                        opacity: 0;
                    }
                }
            }
        }

        @if ($header-transparent-top-links-hidden--xlarge) {
            .c-locale-selector__select,
            .l-header__top-nav-link,
            .l-header__top-nav .c-storeslink,
            .l-header__top-nav .c-signup-link,
            .l-header__top-nav .c-user__link,
            .l-header__top-nav .c-minicart-icon__link,
            .l-header__top-nav .c-minicart-icon__timer,
            .l-header__top-nav .c-select__field {
                transition: $header-transparent-color-transition, $header-transparent-opacity-transition;
            }

            .c-select .c-select__container,
            .c-select .c-select__placeholder,
            .c-user.m-dark {
                transition: $header-transparent-color-transition, $header-transparent-background-transition, $header-transparent-border-transition, $header-transparent-opacity-transition;
            }

            .l-header__top-nav-item > div:first-child::after,
            .l-header__top-nav-item > a:first-child::after,
            .l-header__top-nav-item > button:first-child::after,
            .c-user__link .c-icon__svg::before,
            .c-select.m-secondary .c-select__container::after,
            .c-select.m-secondary .c-select__placeholder::after {
                transition: $header-transparent-background-transition, $header-transparent-opacity-transition;
            }

            @include breakpoint(xlarge) {
                .c-locale-selector__select,
                .l-header__top-nav-link,
                .l-header__top-nav .c-storeslink,
                .l-header__top-nav .c-signup-link,
                .l-header__top-nav .c-user__link,
                .l-header__top-nav .c-minicart-icon__link,
                .l-header__top-nav .c-select__field,
                .c-select .c-select__container,
                .c-select .c-select__placeholder,
                .c-user.m-dark {
                    &:focus {
                        opacity: 1 !important; // stylelint-disable-line
                    }
                }

                .l-header__top-nav-item > div:first-child,
                .l-header__top-nav-item > a:first-child,
                .l-header__top-nav-item > button:first-child {
                    &:focus::after {
                        opacity: 1 !important; // stylelint-disable-line
                    }
                }
            }
        }
        /* stylelint-enable max-nesting-depth, selector-max-compound-selectors */
    }

    // Transparent header with dark text
    .header-transparent.m-transparent-dark {
        .c-logo__image {
            transition: $header-transparent-opacity-transition;
        }

        /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
        .l-header:not(:hover):not(.m-sticked):not(.m-focused):not(.m-search-active):not(.m-search-suggestions-active) {
            @include lora-helper-header-transparent-style($header-transparent-dark-color);

            .c-logo__image {
                opacity: 0;

                &.m-dark {
                    opacity: 1;
                }
            }
        }
        /* stylelint-enable selector-max-compound-selectors, max-nesting-depth */
    }

    // Transparent header with light text
    .header-transparent.m-transparent-light {
        .c-logo__image {
            transition: $header-transparent-opacity-transition;
        }

        /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
        .l-header:not(:hover):not(.m-sticked):not(.m-focused):not(.m-search-active):not(.m-search-suggestions-active) {
            @include lora-helper-header-transparent-style($header-transparent-light-color);

            .c-logo__image {
                opacity: 0;

                &.m-light {
                    opacity: 1;
                }
            }
        }
        /* stylelint-enable selector-max-compound-selectors, max-nesting-depth */
    }
    // Add opacity
    .header-transparent.m-opacity {
        /* stylelint-disable max-nesting-depth */
        .l-header:not(:hover):not(.m-sticked):not(.m-focused):not(.m-search-active):not(.m-search-suggestions-active) {
            .l-header__main-area-wrapper {
                background-color: $header-transparent-opacity-background;

                @include breakpoint(large down) {
                    &::before {
                        display: none;
                    }
                }
            }
        }
        /* stylelint-enable max-nesting-depth */
    }
}
